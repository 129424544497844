import { Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Confirm } from 'components/confirm';
import { CopyInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { Article } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';

export const NetIqCreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Text>
      Create a SAML application in your NetIQ instance. You'll need the
      following Assertion Consumer Service (ACS) URL. The ACS URL is the
      location NetIQ will redirect its authentication response to.
    </Text>

    <CopyInput label="Copy this ACS URL" value={connection?.saml_acs_url} />

    <Text>
      The Service Provider Entity ID is a unique value which represents{' '}
      {appName} within NetIQ. Provide the value below to your NetIQ SAML
      application settings.
    </Text>

    <CopyInput
      label="Copy this SP Entity ID"
      value={connection?.saml_entity_id}
    />

    <Text>
      For some setups, you can use the metadata found at the following SP
      Metadata link to configure the SAML connection.
    </Text>

    <CopyInput
      label="Copy this URL/XML"
      value={connection?.saml_sp_metadata_url}
    />

    <Confirm
      label="I've created and configured a SAML application."
      onClick={onNextStep}
    />
  </Article>
);

export const NetIqUploadMetadata: React.FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://mynetiqserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mynetiqserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      To connect with your NetIQ instance, please upload the NetIQ SAML
      application metadata.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Upload the IdP metadata URL from your SAML settings. Alternatively, you
      can manually configure the connection by providing the SSO URL, IdP Issuer
      and X.509 Certificate.
    </Text>
  </MetadataUrlStep>
);

export const NetIqConfigureClaims: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep,
}) => (
  <Article>
    <Text>
      The final step is to confirm that user attributes map to the following
      SAML claims.
    </Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Confirm
      label="I've finished configuring the SAML claims."
      onClick={onNextStep}
    />
  </Article>
);
